/* stylelint-disable selector-class-pattern */
// This is Cloudinary's white.css, adapted.
#cloudinary-overlay.with_theme {
  display: block;
}

#cloudinary-overlay.modal {
  // background-color: rgba(0,0,0,0.7);
  background-color: #2e0dd7;
}

#cloudinary-widget {
  background: #fff;
  border-radius: 3px;
  border: 1px solid rgb(153 153 153);
}

#cloudinary-navbar {
  background: #f5f5f5;
  border: 1px solid rgb(0 0 0 / 5%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
  border-radius: 4px;
  margin: 20px 20px 10px;
}

#cloudinary-navbar .source {
  border-right: 0;
  border-bottom: 6px solid;
  border-color: transparent;
}

#cloudinary-navbar .source.active {
  background: none;
  border-bottom: 6px solid #037fcb;
}

#cloudinary-navbar .source.active .label {
  color: #000;
}

#cloudinary-widget .drag_area {
  background: #f5f5f5;
  border: 2px dashed rgb(0 0 0 / 10%);
  border-radius: 4px;
  margin: 30px 20px 0;
}

#cloudinary-widget .drag_area.in {
  border-color: #01bb16;
}

#cloudinary-navbar .sources .source.local .icon,
#cloudinary-navbar .sources .source.url .icon,
#cloudinary-navbar .sources .source.camera .icon,
#cloudinary-navbar .sources .source.image_search .icon,
#cloudinary-navbar .sources .source.dropbox .icon,
#cloudinary-navbar .sources .source.facebook .icon,
#cloudinary-navbar .sources .source.google_photos .icon {
  background-position-x: 0;
}

#cloudinary-navbar .close {
  color: rgb(85 85 85);
}

#cloudinary-widget .button,
#cloudinary-widget .button.small_button {
  background: #037fcb;
}

#cloudinary-widget .button:hover,
#cloudinary-widget .button.small_button:hover,
#cloudinary-widget .upload_button_holder:hover .button {
  background: #00619d;
}

#cloudinary-widget .panel {
  height: 437px;
}

#cloudinary-widget .panel.progress .thumbnails .thumbnail .error {
  color: #037fcb;
}

#cloudinary-widget .panel.progress .thumbnails {
  margin-top: 4px;
}

#cloudinary-widget .panel.camera .form .button_holder {
  margin-top: 10px;
  margin-bottom: 10px;
}

#cloudinary-widget .panel.camera .note {
  font-weight: normal;
  font-size: 13px;
  padding: 4px 20px;
}

#cloudinary-widget .panel.camera .video_holder {
  height: 288px;
}

#cloudinary-widget .panel.camera video {
  zoom: 0.6;
  transform: scale(0.6);
  border-width: 0;
}

#cloudinary-widget .camera .form {
  background: #f5f5f5;
  border: 1px solid rgb(0 0 0 / 5%);
  border-radius: 4px;
  margin: 0 20px;
  padding-top: 10px;
}

#cloudinary-overlay.inline .widget {
  border: 1px solid #ddd;
}

@media screen and (max-width: 767px) {
  #cloudinary-widget .drag_area {
    border: none;
    background: none;
  }
}
/* stylelint-enable selector-class-pattern */
